import React from 'react'
import {Carousel, Col, Container, Row} from 'react-bootstrap'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image";
import {Galleria} from "primereact/galleria";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";


const Page = ({ data }) => {
    const { mdx } = data
    const { frontmatter } = mdx
    const { carousel } = frontmatter

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    const itemTemplate = (item) => {
        return <Img fluid={item.childImageSharp.fluid} alt={item.name} className={"w-100 d-block"}/>
    }

    const thumbnailTemplate = (item) => {
        return <Img fixed={item.childImageSharp.fixed} alt={item.name} className={"d-block"} />
    }

    return (
      <Layout>
        <SEO title="Galerie d'images" />
        <Container className="my-md-4 py-4 px-4 rounded text-justify mx-auto">
            <Galleria value={carousel} numVisible={5} style={{maxWidth: '1024px'}}
                      thumbnail={thumbnailTemplate} item={itemTemplate}
                      responsiveOptions={responsiveOptions}
            />

        </Container>
      </Layout>
    )
  }  

export default Page

export const pageQuery = graphql`
    query($id: String) {
      mdx(id: { eq: $id }) {
        frontmatter {
            carousel {
                childImageSharp {
                    fluid(maxWidth: 1024) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    fixed(width: 60) {
                        ...GatsbyImageSharpFixed_withWebp
                    }
                }
            }
        }
      }
    }`
